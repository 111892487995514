<template>
  <div>
    <rs-form :model="itemForm" label-width="140px" style="width:70%;" :show-message="false">
      <rs-form-item label="登录页背景图：" class="form-item">
        <rs-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess.bind(this, 'customLoginBgimg')"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="itemForm.customLoginBgimg" :src="itemForm.customLoginBgimg" class="avatar" />
          <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
        </rs-upload>
      </rs-form-item>
      <rs-form-item label="登录页图标：" class="form-item">
        <rs-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess.bind(this, 'customLoginLogo')"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="itemForm.customLoginLogo" :src="itemForm.customLoginLogo" class="avatar" />
          <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
        </rs-upload>
      </rs-form-item>
      <rs-form-item label="登录页标题：" class="form-item">
        <rs-input v-model="itemForm.customLoginTitle"></rs-input>
      </rs-form-item>

      <rs-form-item label="浏览器菜单图标：" class="form-item">
        <rs-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess.bind(this, 'customHtmlHeadLogo')"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="itemForm.customHtmlHeadLogo" :src="itemForm.customHtmlHeadLogo" class="avatar" />
          <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
        </rs-upload>
      </rs-form-item>
      <rs-form-item label="浏览器菜单标题：" class="form-item">
        <rs-input v-model="itemForm.customHtmlHeadTitle"></rs-input>
      </rs-form-item>
      <rs-form-item label="系统首页图标：" class="form-item">
        <rs-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess.bind(this, 'customIndexLogo')"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="itemForm.customIndexLogo" :src="itemForm.customIndexLogo" class="avatar" />
          <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
        </rs-upload>
      </rs-form-item>
      <rs-form-item label="系统首页标题：" class="form-item">
        <rs-input v-model="itemForm.customIndexTitle"></rs-input>
      </rs-form-item>
       <rs-form-item label="商户小程序二维码：" class="form-item">
        <rs-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess.bind(this, 'customMerchantImg')"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="itemForm.customMerchantImg" :src="itemForm.customMerchantImg" class="avatar" />
          <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          <div class="delCls" v-if="itemForm.customMerchantImg">
            <i class="rs-icon-delete" @click.stop="delApplet('customMerchantImg')"></i>
          </div>
        </rs-upload>
      </rs-form-item>
      <rs-form-item label="会员小程序二维码：" class="form-item">
        <rs-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess.bind(this, 'customAppletVipImg')"
          :before-upload="beforeAvatarUpload"
        >
         
          <img v-if="itemForm.customAppletVipImg" :src="itemForm.customAppletVipImg" class="avatar" />
          <i v-else class="rs-icon-plus avatar-uploader-icon"></i>
          <div class="delCls" v-if="itemForm.customAppletVipImg">
            <i class="rs-icon-delete" @click.stop="delApplet('customAppletVipImg')"></i>
          </div>
        </rs-upload>
   
      </rs-form-item>
    </rs-form>
    <rs-create-button v-if="type != 'detail'" :buttonList="buttonList" @save="save"></rs-create-button>
  </div>
</template>

<script>
import Api from '@/api/imgSetter.js'
export default {
  name: 'imgSetter.vue',
  data() {
    return {
      itemForm: {
        customHtmlHeadTitle: '',
        customIndexTitle: '',
        customLoginTitle: '',
        customAppletVipImg: '',
        customMerchantImg: ''
      },
      buttonList: [
        {
          name: '保存',
          type: 'primary',
          function: 'save'
        }
      ],
      uploadUrl: '',
      imageUrl: '',
      type: ''
    }
  },
  methods: {
    getList() {
      Api.findAllConfig({
        configType: 'custom',
        pageNum: 1,
        pageSize: 99
      }).then(res => {
        res.data.data.list.forEach(item => {
          this.itemForm[item.configType] = item.configValue
        })
        console.log(this.itemForm)
      })
    },
    save() {
      let configList = []
      for (let item in this.itemForm) {
        configList.push({
          configType: item,
          configValue: this.itemForm[item]
        })
      }
      console.log(configList)
      Api.save({
        configList: configList
      }).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        }
      })
    },
    handleAvatarSuccess(item, response, file, fileList) {
      this.itemForm[item] = response.data.fileUrl
      this.$forceUpdate()
    },
    beforeAvatarUpload() {},
    delApplet(type){
      this.$set(this.itemForm,type,'')
    }
  },
  mounted() {
    this.uploadUrl = Api.uploadUrl()
  this.getList()
  },
  watch: {
    itemForm: {
      handler(val) {},
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped>
.avatar-uploader /deep/ .rs-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .rs-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}
.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
/deep/ .rs-button {
  height: 32px;
  line-height: 32px;
  padding: 0;
}
.rs-upload:hover .delCls{
  display: block;
}
.delCls{
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    transition: opacity .3s;
    display: none;
   
}
 .delCls i{
          color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    }
</style>
