var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "rs-form",
        {
          staticStyle: { width: "70%" },
          attrs: {
            model: _vm.itemForm,
            "label-width": "140px",
            "show-message": false,
          },
        },
        [
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "登录页背景图：" } },
            [
              _c(
                "rs-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess.bind(
                      this,
                      "customLoginBgimg"
                    ),
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.itemForm.customLoginBgimg
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.itemForm.customLoginBgimg },
                      })
                    : _c("i", {
                        staticClass: "rs-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "登录页图标：" } },
            [
              _c(
                "rs-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess.bind(
                      this,
                      "customLoginLogo"
                    ),
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.itemForm.customLoginLogo
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.itemForm.customLoginLogo },
                      })
                    : _c("i", {
                        staticClass: "rs-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "登录页标题：" } },
            [
              _c("rs-input", {
                model: {
                  value: _vm.itemForm.customLoginTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemForm, "customLoginTitle", $$v)
                  },
                  expression: "itemForm.customLoginTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "浏览器菜单图标：" } },
            [
              _c(
                "rs-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess.bind(
                      this,
                      "customHtmlHeadLogo"
                    ),
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.itemForm.customHtmlHeadLogo
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.itemForm.customHtmlHeadLogo },
                      })
                    : _c("i", {
                        staticClass: "rs-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "浏览器菜单标题：" } },
            [
              _c("rs-input", {
                model: {
                  value: _vm.itemForm.customHtmlHeadTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemForm, "customHtmlHeadTitle", $$v)
                  },
                  expression: "itemForm.customHtmlHeadTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "系统首页图标：" } },
            [
              _c(
                "rs-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess.bind(
                      this,
                      "customIndexLogo"
                    ),
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.itemForm.customIndexLogo
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.itemForm.customIndexLogo },
                      })
                    : _c("i", {
                        staticClass: "rs-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            { staticClass: "form-item", attrs: { label: "系统首页标题：" } },
            [
              _c("rs-input", {
                model: {
                  value: _vm.itemForm.customIndexTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.itemForm, "customIndexTitle", $$v)
                  },
                  expression: "itemForm.customIndexTitle",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "商户小程序二维码：" },
            },
            [
              _c(
                "rs-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess.bind(
                      this,
                      "customMerchantImg"
                    ),
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.itemForm.customMerchantImg
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.itemForm.customMerchantImg },
                      })
                    : _c("i", {
                        staticClass: "rs-icon-plus avatar-uploader-icon",
                      }),
                  _vm.itemForm.customMerchantImg
                    ? _c("div", { staticClass: "delCls" }, [
                        _c("i", {
                          staticClass: "rs-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delApplet("customMerchantImg")
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "会员小程序二维码：" },
            },
            [
              _c(
                "rs-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.uploadUrl,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess.bind(
                      this,
                      "customAppletVipImg"
                    ),
                    "before-upload": _vm.beforeAvatarUpload,
                  },
                },
                [
                  _vm.itemForm.customAppletVipImg
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.itemForm.customAppletVipImg },
                      })
                    : _c("i", {
                        staticClass: "rs-icon-plus avatar-uploader-icon",
                      }),
                  _vm.itemForm.customAppletVipImg
                    ? _c("div", { staticClass: "delCls" }, [
                        _c("i", {
                          staticClass: "rs-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delApplet("customAppletVipImg")
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type != "detail"
        ? _c("rs-create-button", {
            attrs: { buttonList: _vm.buttonList },
            on: { save: _vm.save },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }